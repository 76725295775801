<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	name: "App",
	// created() {
	// 	var image = new Image()
	// 	image.onload = function () {
	// 		let style = [
	// 			"padding: 23px 120px;",
	// 			"background: url(" + this.src + ") no-repeat;",
	// 		].join(" ")
	// 		console.log("%c ", style)
	// 	}
	// 	image.src = require("/src/assets/3DONS-white.png")
	// },
}
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Gowun+Dodum&family=IBM+Plex+Sans+KR:wght@300&family=Poor+Story&family=Roboto:wght@500&display=swap");

html,
body,
#app {
	height: 100%;
	font-family: "Poor Story", "Gowun Dodum", "IBM Plex Sans KR", cursive;
	font-size: 0.9rem;
}
.btn-secondary {
	border-color: rgb(13, 40, 82) !important;
}
.nav-pills .nav-link.active,
.nav-color,
.btn-secondary {
	background-color: rgb(13, 40, 82) !important;
}
.card {
	border: 1px solid rgba(13, 40, 82, 0.5) !important;
}
header.card-header {
	background-color: rgb(208, 226, 255) !important;
}
.card-header {
	border-bottom: 1px solid rgba(13, 40, 82, 0.25) !important;
	background-color: #fff !important;
}
.page {
	margin-top: 60px;
}
</style>
