import ApiService from "../common/api.service"
import JwtService from "../common/jwt.service"
import { LOGOUT, REGISTER } from "./actions.type"
import { SET_AUTH, SET_ERROR, PURGE_AUTH } from "./mutations.type"
import { consoleLog } from "../common/utils"

const state = {
	errors: null,
	user: {},
	isAuthenticated: !!JwtService.getToken(),
}

const getters = {
	currentUser(state) {
		return state.user
	},
	isAuthenticated(state) {
		return state.isAuthenticated
	},
}

const actions = {
	[REGISTER](context, credentials) {
		return new Promise((resolve) => {
			consoleLog(credentials)
			ApiService.post("signup", credentials)
				.then(({ data }) => {
					consoleLog("register success")
					resolve(data)
				})
				.catch(({ response }) => context.commit(SET_ERROR, response.data))
		})
	},
	[LOGOUT](context) {
		context.commit(PURGE_AUTH)
	},
}

const mutations = {
	[SET_ERROR](state, error) {
		state.errors = error
		state.isError = true
	},
	[SET_AUTH](state, user) {
		state.isAuthenticated = true
		state.user = user
		state.errors = {}
		JwtService.saveToken(user.token)
	},
	[PURGE_AUTH](state) {
		state.isAuthenticated = false
		state.user = {}
		state.errors = {}
		JwtService.destroyToken()
	},
}

export default {
	state,
	actions,
	mutations,
	getters,
}
