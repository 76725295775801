import Vue from "vue"
import axios from "axios"
import dayjs from "dayjs"
import VueAxios from "vue-axios"
import JwtService from "./jwt.service"
import { API_URL } from "./config"

const ApiService = {
	init() {
		Vue.use(VueAxios, axios)
		Vue.axios.defaults.baseURL = API_URL
	},

	setHeader() {
		Vue.axios.defaults.headers.common[
			"Authorization"
		] = `Bearer ${JwtService.getToken()}`
	},

	// with query string
	query(resource, params) {
		const date = dayjs().format("YYYY-MM-DD HH:mm:ss")
		if (process.env.NODE_ENV != "production") {
			console.log(
				"GET",
				"url: ",
				resource,
				", time:",
				date,
				", params:",
				params
			)
		}
		return Vue.axios.get("/api/" + resource, params).catch((error) => {
			throw new Error(`ApiService ${error}`)
		})
	},

	get(resource, slug = "") {
		const date = dayjs().format("YYYY-MM-DD HH:mm:ss")
		if (process.env.NODE_ENV != "production") {
			console.log("GET", "url: ", resource, ", time:", date, ", slug:", slug)
		}
		const params = slug ? "/" + slug : ""
		return Vue.axios.get("/api/" + `${resource}${params}`).catch((error) => {
			throw new Error(`ApiService ${error}`)
		})
	},

	post(resource, params) {
		const date = dayjs().format("YYYY-MM-DD HH:mm:ss")
		if (process.env.NODE_ENV != "production") {
			console.log(
				"POST",
				"url: ",
				resource,
				", time:",
				date,
				", params:",
				params
			)
		}
		return Vue.axios.post("/api/" + `${resource}`, params)
	},

	update(resource, slug, params) {
		const date = dayjs().format("YYYY-MM-DD HH:mm:ss")
		if (process.env.NODE_ENV != "production") {
			console.log(
				"PATCH",
				"url: ",
				resource,
				", time:",
				date,
				", slug:",
				slug,
				", params:",
				params
			)
		}

		return Vue.axios.put("/api/" + `${resource}/${slug}`, params)
	},

	put(resource, params) {
		const date = dayjs().format("YYYY-MM-DD HH:mm:ss")
		if (process.env.NODE_ENV != "production") {
			console.log(
				"PUT",
				"url: ",
				resource,
				", time:",
				date,
				", params:",
				params
			)
		}
		return Vue.axios.put("/api/" + `${resource}`, params)
	},

	delete(resource) {
		const date = dayjs().format("YYYY-MM-DD HH:mm:ss")
		if (process.env.NODE_ENV != "production") {
			console.log("DELETE", "url: ", resource, ", time:", date, "")
		}
		return Vue.axios.delete("/api/" + resource).catch((error) => {
			throw new Error(`ApiService ${error}`)
		})
	},
}

export default ApiService
