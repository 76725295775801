import Vue from "vue"
import { BootstrapVue, ModalPlugin } from "bootstrap-vue"
import router from "../router"
import { cloneDeep } from "lodash/fp"

Vue.use(BootstrapVue)
Vue.use(ModalPlugin)

let vue = new Vue()

export function consoleLog(msg, data) {
	if (process.env.NODE_ENV != "production") {
		if (data) {
			const copyData = cloneDeep(data)
			return console.log(msg, copyData)
		} else {
			return console.log(msg)
		}
	}
}

export function errorLog(response, slug = null) {
	if (process.env.NODE_ENV != "production") {
		return console.log(response)
	} else {
		vue.$bvModal.msgBoxOk(response.toJSON().message).then(() => {
			return slug != null ? router.push({ name: slug }) : null
		})
	}
}

// $bvModal error
export function modalErr(err) {
	if (process.env.NODE_ENV != "production") {
		return console.log(err)
	}
}
