// export const API_URL =
// 	process.env.NODE_ENV == "production"
// 		? "http://www.3donsbank.net" || "http://15.164.53.31"
// 		: "http://127.0.0.1:8000"
export const API_URL =
	process.env.NODE_ENV == "production"
		? "https://www.3donsbank.net" || "https://15.164.53.31"
		: "http://127.0.0.1:8000"

export default API_URL
