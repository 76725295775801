import Vue from "vue"
import VueDayjs from "vue-dayjs-plugin"
import App from "./App.vue"
import router from "./router"
import store from "./store"

import ApiService from "./common/api.service"
import JwtService from "./common/jwt.service"
import { consoleLog } from "./common/utils"

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-vue/dist/bootstrap-vue.css"

Vue.use(VueDayjs)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.config.productionTip = false

ApiService.init()

// Ensure we checked auth before each page load.
router.beforeEach((to, from, next) => {
	if (JwtService.getToken()) {
		ApiService.setHeader()
		ApiService.get("user")
			.then(({ data }) => {
				consoleLog("user me response : ", data)
				if (data.result_code == 0) {
					data.token = JwtService.getToken()
					store.commit("setUser", data)
				} else {
					store.commit("logout")
				}
				if (!store.getters.isAuthenticated && to.path !== "/login") {
					next({ name: "login" })
				} else if (store.getters.isAuthenticated && to.path === "/") {
					next({ name: "reports" })
				} else {
					next()
				}
			})
			.catch((response) => {
				store.commit("setError", response)
				store.commit("logout")
				next({ name: "login" })
			})
	} else {
		store.commit("logout")
		if (to.name !== "login") {
			next({ name: "login" })
		} else {
			next()
		}
	}
})

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app")
