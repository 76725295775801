import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)

export default new Router({
	mode: "history",
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
	routes: [
		{
			name: "home",
			path: "/",
			component: () => import("../views/Home"),
			children: [
				{
					path: "reports",
					name: "reports",
					meta: {
						title: "정산금액",
					},
					component: () => import("../components/Report"),
				},
				{
					path: "reports/history",
					name: "reports-history",
					meta: {
						title: "정산히스토리",
					},
					props: true,
					component: () => import("../components/ReportHistory"),
				},
				{
					path: "report-form",
					name: "report-form",
					meta: {
						title: "정산하기",
					},
					component: () => import("../components/ReportForm"),
				},
				{
					path: "/reports/:id",
					name: "reports-update",
					meta: {
						title: "정산수정",
					},
					props: true,
					component: () => import("../components/ReportForm"),
				},
			],
		},
		{
			name: "login",
			path: "/login",
			component: () => import("../views/Login"),
		},
	],
})
